<template>
	<a-card class="guest-container">
		<template #title>
			<div class="header">
				<a-button type="primary" @click="handleAdd">新增发布消息</a-button>
			</div>
		</template>
		<a-table :loading="loading" :columns="columns" :data-source="tableData" :pagination="false">
			<template #bodyCell="{ column, record }">
				<template v-if="column.key === 'content'">
					<div class="item" v-for="(item, index) in record.items" :key="index">
						<a-tag :color="typeMapping[item.itemType].color">{{ typeMapping[item.itemType].label }}</a-tag>
						{{ item.title }}:
						{{ item.subTitle }}
					</div>
				</template>
				<template v-if="column.key === 'status'">
					{{ statusMapping[record.status] }}
				</template>
				<template v-if="column.key === 'action'">
					<span v-if="record.status === 'draft'">
						<a @click="handleEdit(record)" style="margin-right: 20px">编辑</a>
						<a-popconfirm title="确定发布吗?" ok-text="是" cancel-text="否" @confirm="changeStatus(record, 'published')">
							<a style="margin-right: 20px">发布</a>
						</a-popconfirm>
						<a-popconfirm title="确定删除吗?" ok-text="是" cancel-text="否" @confirm="changeStatus(record, 'deleted')">
							<a style="margin-right: 20px">删除</a>
						</a-popconfirm>
					</span>
				</template>
			</template>
		</a-table>
		<a-pagination
			style="float: right; margin-top: 10px"
			v-model:current="pageNo"
			v-model:pageSize="pageSize"
			show-size-changer
			:total="total"
			:show-total="(total) => `总计 ${total} 条`"
			@change="handleChangePage"
		>
			<template v-slot:buildOptionText="props">
				<span>{{ props.value }}条/页</span>
			</template>
		</a-pagination>
	</a-card>
	<a-modal v-model:visible="craeteVisible" width="800px" title="新增发布消息" @ok="createHandle">
		<a-descriptions :column="1" size="small">
			<a-descriptions-item label="版本号">
				<a-input style="width: 200px" v-model:value="modalData.msgTag"></a-input>
				<span class="ml10">推荐{{ recommendVersion }}</span>
				<span class="text-link" @click="modalData.msgTag = recommendVersion">填入</span>
			</a-descriptions-item>
			<a-descriptions-item label="产品线" style="margin-top: 10px; display: block">
				<a-radio-group v-model:value="modalData.allProductLine">
					<a-radio :value="true">全部</a-radio>
					<a-radio :value="false">指定</a-radio>
				</a-radio-group>
				<div v-if="!modalData.allProductLine">
					<a-select style="width: 150px" v-model:value="modalData.productLineCode">
						<a-select-option value="OMS">OMS</a-select-option>
						<a-select-option value="WMS">WMS</a-select-option>
					</a-select>
				</div>
			</a-descriptions-item>
			<a-descriptions-item label="国家" style="margin-top: 10px; display: block">
				<a-radio-group v-model:value="modalData.allCountry">
					<a-radio :value="true">全部</a-radio>
					<a-radio :value="false">指定</a-radio>
				</a-radio-group>
				<div v-if="!modalData.allCountry">
					<a-select style="width: 150px" v-model:value="modalData.countryCode">
						<a-select-option value="CN">国内</a-select-option>
						<a-select-option value="EN">国外</a-select-option>
					</a-select>
				</div>
			</a-descriptions-item>
			<a-descriptions-item label="发布内容" style="margin-top: 10px; display: block">
				<a-button type="primary" @click="addItem">添加</a-button>
			</a-descriptions-item>
		</a-descriptions>
		<a-table style="width: 100%" :loading="loading" :columns="modalColumns" :data-source="modalData.items" :pagination="false">
			<template #bodyCell="{ column, record, index }">
				<template v-if="['title', 'subTitle', 'externalLink'].includes(column.key)">
					<a-input v-model:value="modalData.items[index][column.key]" />
				</template>
				<template v-if="column.key === 'itemType'">
					<a-select style="width: 150px" v-model:value="record.itemType">
						<a-select-option v-for="(item, key) in typeMapping" :value="key" :key="key">{{ typeMapping[key].label }}</a-select-option>
					</a-select>
				</template>
				<template v-if="column.key === 'action'">
					<a @click="deleteItem(index)">删除</a>
				</template>
			</template>
		</a-table>
	</a-modal>
</template>

<script setup>
import { debounce, cloneDeep } from "lodash"
import { ref, onMounted, computed, nextTick } from "vue"
import axios from "@/common/axios"
import api from "@/common/api/api"
import { message } from "ant-design-vue"

const loading = ref(false)
const tableData = ref([])
const pageNo = ref(1)
const pageSize = ref(20)
const total = ref(0)
const craeteVisible = ref(false)
const modalData = ref({})

onMounted(() => {
	getList()
})

const typeMapping = computed(() => ({
	feature: {
		label: "新功能",
		color: "green",
	},
	bug_fix: {
		label: "问题修复",
		color: "volcano",
	},
	improvement: {
		label: "优化",
		color: "orange",
	},
	other: {
		label: "其他",
		color: "blue",
	},
}))

const columns = computed(() => {
	let list = [
		{
			title: "版本号",
			dataIndex: "msgTag",
			key: "msgTag",
		},
		{
			title: "产品线",
			dataIndex: "productLineCode",
			key: "productLineCode",
		},
		{
			title: "内容",
			dataIndex: "content",
			key: "content",
		},
		{
			title: "状态",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "创建时间",
			dataIndex: "createAt",
			key: "createAt",
		},
		{
			title: "更新时间",
			dataIndex: "updateAt",
			key: "updateAt",
		},
		{
			title: "操作",
			key: "action",
		},
	]
	return list
})

const modalColumns = computed(() => {
	let list = [
		{
			title: "类型",
			key: "itemType",
		},
		{
			title: "标题",
			key: "title",
		},
		{
			title: "副标题",
			key: "subTitle",
		},
		{
			title: "链接",
			key: "externalLink",
		},
		{
			title: "操作",
			key: "action",
			width: 100,
		},
	]
	return list
})

const recommendVersion = ref("")

const statusMapping = computed(() => ({
	draft: "草稿",
	deleted: "已删除",
	published: "已发布",
}))

const getList = () => {
	const data = {
		page_no: pageNo.value,
		page_size: pageSize.value,
	}
	axios.post(api.getSystemMessageList, data).then((res) => {
		tableData.value = res.list
		total.value = res.total
		if (res.list.length > 0) {
			let tag = res.list[0].msgTag
			let tagArr = tag.split(".")
			tagArr[tagArr.length - 1] = +tagArr[tagArr.length - 1] + 1
			recommendVersion.value = tagArr.join(".")
		}
	})
}
const handleAdd = () => {
	modalData.value = {
		msgTag: "",
		allProductLine: true,
		productLineCode: "OMS",
		allCountry: false,
		countryCode: "CN",
		items: [],
	}
	craeteVisible.value = true
	nextTick(() => {
		addItem()
	})
}
const handleEdit = (record) => {
	modalData.value = cloneDeep(record)
	craeteVisible.value = true
}
const changeStatus = (record, status) => {
	axios
		.post(api.changeSystemMessageStatus, {
			id: record.id,
			status,
		})
		.then(() => {
			message.success("操作成功")
			getList()
		})
}
const handleChangePage = (page, page_size) => {
	pageNo.value = page
	pageSize.value = page_size
	getList()
}
const addItem = () => {
	modalData.value.items.push({
		title: "",
		subTitle: "",
		itemType: "",
		externalLink: "",
	})
}
const createHandle = debounce(() => {
	let url = api.createSystemMessage
	const data = {
		msgType: "release_note",
		msgTag: modalData.value.msgTag,
		allCountry: modalData.value.allCountry,
		countryCode: modalData.value.countryCode,
		allProductLine: modalData.value.allProductLine,
		productLineCode: modalData.value.productLineCode,
		alert: true,
		items: modalData.value.items,
	}
	if (modalData.value.id) {
		data.id = modalData.value.id
		url = api.updateSystemMessage
	}
	axios.post(url, data).then(() => {
		craeteVisible.value = false
		if (modalData.value.id) {
			message.success("修改成功")
		} else {
			message.success("新增成功")
		}
		getList()
	})
})
const deleteItem = (index) => {
	modalData.value.items.splice(index, 1)
}
</script>

<style>
.ml10 {
	margin-left: 10px;
}
.text-link {
	cursor: pointer;
	margin-left: 8px;
	color: #1890ff;
}
</style>
